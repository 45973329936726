<ion-app>
  <router-outlet style="position: absolute"></router-outlet> 
</ion-app>

<div class="approve-login-web-container" [ngStyle]="{'display': displayApproveLogin ? 'block' : 'none'}">
  <div class="approve-login-web-panel">
    <div class="approve-login-web-panel-content">
        <div class="title-message">
        {{titleMessage}}
        </div>
        <div class="text-message">
          {{textMessage}}
        </div>
        <div class="button-container" (click)="acceptApproveLogin()" style="padding-top:30px;">
        <div class="btn-submit-approve-login">{{textYesButton}}</div>
        </div>
        <div class="button-container" (click)="cancelApproveLogin()">
          <div class="btn-submit-approve-login">{{textNotButton}}</div>
        </div>
    </div>
  </div>
</div>

<ng-template [ngIf]="sharedData.displayUpdatingWindow">
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
</ng-template>

<div  class="updating-window-container" [ngStyle]="{'display': sharedData.displayUpdatingWindow ? 'block' : 'none'}">   
  <div style="padding: 30px; text-align: center; text-align: -webkit-center; height: 60%; overflow: auto; margin: auto; position: absolute; top: 0; left: 0; bottom: 0; right: 0;" class="text-center">
    <i style="font-size: 50px; padding: 20px;" class="fas fa-cloud-download-alt"></i>
    <br>
    <span style="color: #fff;"> 
        {{sharedData.updatingAppText}}
    </span>
    <br><br>
    <div class="spinner-grow text-light" role="status">
        <span class="sr-only">
         {{sharedData.loadingText}} 
        </span>
    </div>
    <br><br>
    <span style="color: #fff;">
        {{sharedData.pleaseWaitText}}
    </span>
  </div>
</div>