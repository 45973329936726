import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtmlPipe'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(html: string): SafeHtml {
    let regex = new RegExp(/href="(?!javascript)([\S]+)"/g);
    html = html.replace(regex, "href = \"javascript:void(0);\" onClick=\"window.open('$1', '_system', 'location=yes')\"");
    let result = this.domSanitizer.bypassSecurityTrustHtml(html);
    return result;
  }
}
