import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SharedDataService {

  UrlDiscovery: string = 'https://discover.businesswideweb.net/httpHandler/Discovery.ashx';
  UrlTicketsServerHttp: string = 'https://tickets.businesswideweb.net/httpHandler/TicketProcess.ashx';
  UrlTicketsServerWS: string = 'wss://tickets.businesswideweb.net/httpHandler/TicketProcess.ashx';
  OnlineOfficeEditorURL: string = 'https://documents.businesswideweb.net';

  webSocketResponse: string[] = [];
  deviceGuid: string = '';
  portalGuid: string = '';
  bussinessSuiteVersion = '';
  sessionKey: string = '';
  communicationKey: string = '';
  viewCounts = [];
  viewVersions = [];
  queryTop: number = 50;
  rowsPerPage: number = 25;
  validPassword: boolean = false;
  translations = [];
  spellcheckLang = "";
  defaultFontEditor = "";
  spellcheckLocalization = "";
  portalUserGUID = "";
  PortalName = "";
  portalUserID = "";
  portalUserDisplayName = '';
  appFilters = [];
  appURL: string = '';

  minWidthPreview: number = 474;//px
  minWidthCardListColumn: number = 350;// minWidthCardListColumn + minWidthPreview + MenuWidth(200) should be less than 1024px-6px
  minWidthTableView: number = 350;
  menuWidth: number = 200;  
  maxWidthPopup:number=1100;
  minHeightFroala:number=100;
  
  params: any;
  channel: string;
  disableListboard:boolean;
  displayUpdatingWindow:boolean=false;
  loadingText:string;
  pleaseWaitText:string;
  updatingAppText:string;
  progressDownloading:string;
  boGUIDPlugin:any;
  currentInlinePreview:any={};
  showCardDetail:boolean=true;
  browseParams:any;
  queryTopTreeMode: number = 100000;
  GUIDDocumentsListboard:string='aa208582-b5e1-40de-a367-6ad4cbc0bb76';
  userSettings:any[]=[];
  listEditItemPopUp:any[]=[];
  listCardDetailPopUp: Array<any> = [];
  listCardListModalPopUp:any[]=[];
  listFilterListPopUp:any[]=[];
  currentTooltip:any={};

  constructor() {

  }

}
